import { Environment } from './model'

export const environment: Environment = {
  baseUrl: 'https://app.go-planner.com',
  production: true,
  api: {
    url: '/api',
    version: 'v1'
  },
  tokens: {
    mapbox:
      'pk.eyJ1IjoiZnJhbmNpc2NvMTA3IiwiYSI6ImNsZGthdzUxMjAwNGczcW1yYmY3eGwweGwifQ.2MVScz-mVBayubsRAOCfPw',
    ptv: 'RVVfOWIzMGMzMGIzOGIzNDU3MWJkZTYxNTY3YWI2OTkyZTU6MGEzZDVjMjYtZGZmYS00Njg0LTk3ZmQtMjQzNmQyZGJkZWI1'
  }
}
